const services = [
  {
    name: "Kubernetes",
    description:
      "Kubernetes, also known as K8s, is an open-source system for automating deployment, scaling, and management of containerized applications.",
    link: "Learn more",
    url: "https://www.credly.com/badges/00e70409-9f84-472e-bb1d-a331528e19dc",
  },
  {
    name: "Terraform",
    description:
      "Terraform is an open-source infrastructure as code tool that enables you to safely and predictably provision and manage infrastructure in any cloud.",
    link: "Learn more",
    url: "https://www.credly.com/badges/4e627ab6-4169-4ae7-b8f3-e189f8532c5b",
  },
  {
    name: "Prometheus",
    description:
      "An open-source monitoring system with a dimensional data model, flexible query language, efficient time series database and modern alerting approach.",
    link: "Learn more",
    url: "https://www.credly.com/badges/cb1d5254-6dbf-4a4c-a04c-b5a328bba1af",
  },
  {
    name: "Azure DevOps",
    description:
      "Azure DevOps is a set of development tools that enables software development teams to plan, develop, test, deploy, and monitor applications on various platforms.",
    link: "Learn more",
    url: "https://www.credly.com/badges/c12fe39d-0c29-4e04-a3a5-5cce16366f11",
  },
];

export default services;
