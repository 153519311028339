import React from "react";
// countup
import CountUp from "react-countup";
// intersection observer hook
import { useInView } from "react-intersection-observer";
//motion
import { motion } from "framer-motion";
//variant
import { fadeIn } from "../variants";
import { Link } from "react-scroll";

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section id="about" className="section" ref={ref}>
      <div className="container mx-auto">
        <div
          className="flex flex-col gap-y-10 lg:flex-row lg:items-center
        lg:gap-x-20 lg:gap-y-0 h-screen"
        >
          {/* img */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 bg-about bg-contain bg-no-repeat h-[640px] opacity-50 bg-top"
          ></motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <h2 className="h2 text-accent">About me.</h2>
            <h3 className="h3 mb-4">
              I'm a DevOps Engineer with over 3 years of experience.
            </h3>
            <p className="mb-6">
              I started my career in IT implementing RPA in the public sector
              and gradually transited into more technical roles such as cloud
              migration before finally settling in DevOps.
            </p>
            {/* stats */}
            <div className="flex gap-x-6 lg:gap-x-10 mb-12">
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-1 pt-1">
                  {inView ? <CountUp start={0} end={3} duration={1} /> : null}
                  <div className="font-primary text-sm tracking-[2px]">
                    Years of <br />
                    Experience
                  </div>
                </div>
              </div>
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-1 pt-1">
                  {inView ? <CountUp start={0} end={16} duration={1} /> : null}
                  <div className="font-primary text-sm tracking-[2px]">
                    Projects <br />
                    Automated
                  </div>
                </div>
              </div>
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-1 pt-1">
                  {inView ? <CountUp start={0} end={10} duration={1} /> : null}+
                  <div className="font-primary text-sm tracking-[2px]">
                    Certifications <br />
                    in DevOps
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-x-8 items-center">
              <Link to="contact">
                <button className="btn btn-lg">Contact me</button>
              </Link>
              <Link to="work" className="text-gradient btn-link">
                My Portfolio
              </Link>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
