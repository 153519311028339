import axios from "axios";
import { useState, useEffect } from "react";
// motion
import { motion } from "framer-motion";
//variants
import { fadeIn } from "../variants";

const GETFORM_FORM_ENDPOINT = process.env.REACT_APP_YOUR_GETFORM_ENDPOINT;

const Contact = () => {
  const initialValues = { yourname: "", youremail: "", yourmessage: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setFormValues({
        yourname: "",
        youremail: "",
        yourmessage: "",
      });
      axios.post(GETFORM_FORM_ENDPOINT, formValues, {
        headers: { Accept: "application/json" },
      });
    }
  }, [formErrors]);

  const validateForm = (values) => {
    const errors = {};
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
    if (!values.yourname) {
      errors.yourname = "Name is required!";
    }
    if (!values.youremail) {
      errors.youremail = "Email is required!";
    } else if (!regex.test(values.youremail)) {
      errors.youremail = "This is not a valid email format!";
    }
    if (!values.yourmessage) {
      errors.yourmessage = "Message is required!";
    }
    return errors;
  };

  return (
    <section id="contact" className="lg:section py-16">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex justify-start items-center"
          >
            <div>
              <h4 className="text-xl uppercase text-accent font-medium mb-2 tracking-wide">
                Get in touch
              </h4>
              <h2 className="text-[45px] lg:text-[90px] leading-none mb-12">
                Let's work <br /> together!
              </h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 border border-accent rounded-2xl flex flex-col gap-y-6
          pb-24 p-6 items-stretch"
          >
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <input
                className="bg-transparent border-b py-3 
              outline-none w-full placeholder:text-accent focus: border-accent transition-all"
                name="yourname"
                type="text"
                placeholder="Your name"
                value={formValues.yourname}
                onChange={handleChange}
              />
              <span style={{ color: "red" }}>{formErrors.yourname}</span>
              <input
                className="bg-transparent border-b py-3 
              outline-none w-full placeholder:text-accent focus: border-accent transition-all"
                name="youremail"
                type="text"
                placeholder="Your email"
                value={formValues.youremail}
                onChange={handleChange}
              />
              <span style={{ color: "red" }}>{formErrors.youremail}</span>
              <textarea
                className="bg-transparent border-b py-12 
              outline-none w-full placeholder:text-accent focus: border-accent transition-all 
              resize-none mb-12"
                name="yourmessage"
                placeholder="Your message"
                value={formValues.yourmessage}
                onChange={handleChange}
              ></textarea>
              <span style={{ color: "red" }}>{formErrors.yourmessage}</span>
              <input
                type="hidden"
                name="_gotcha"
                style={{ display: "none !important" }}
              ></input>
              <p>
                {Object.keys(formErrors).length === 0 && isSubmit
                  ? "Form submitted successfully"
                  : ""}
              </p>
              <button className="btn btn-lg">Send message</button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
